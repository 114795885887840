<script>
import { Map } from '@/api'
import ListMixin from '@/crudBases/list/ListMixin.vue'

export default {
  mixins: [ListMixin],
  data() {
    return {
      editRouteName: 'Edit Map',
      addRoute: { name: 'Add Map' },
      verboseName: 'Mapa',
      verboseNamePlural: 'Mapy',
      listFields: [
        { prop: 'id', label: 'id', width: 80 },
        { prop: 'name', label: 'Nazwa' },
        { prop: 'is_active', label: 'Aktywna mapa', type: 'boolean' }
      ]
    }
  },
  computed: {
    service() {
      return Map
    }
  },
  methods: {
    additionalActions(scope) {
      return [
        this.getButtonComponent({
          icon: 'fa fa-copy',
          onClick: () => this.cloneItem(scope.row)
        })
      ]
    },
    async cloneItem(row) {
      let newRow = structuredClone(row)
      delete newRow.id
      newRow['is_active'] = false
      newRow['name'] = newRow['name'] + '(copy)'
      const response = await Map.save(newRow)
      const newItem = response.data
      this.$router.push({
        name: this.editRouteName,
        params: { id: newItem.id }
      })
    }
  }
}
</script>
