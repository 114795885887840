<template>
  <form-wrapper @submit="onSubmit">
    <div slot="box" v-loading="loading">
      <component-compiler
        v-for="(component, i) in getFields()"
        :key="`form-${i}`"
        :content="component"
      />
    </div>
    <form-nav
      slot="navbar"
      :loading="loading"
      :redirect-route="redirectRoute"
      :in-modal="inModal"
      :hide-multilingual="hideMultilingual"
      @submit="onSubmit"
    >
      <component-compiler
        v-for="(component, i) in getNavButtons()"
        :key="`form-${i}`"
        :content="component"
      />
    </form-nav>
  </form-wrapper>
</template>
<script>
import FormNav from './FormNav'
import FormWrapper from './FormWrapper'
import { cloneDeep, isEqual, get } from 'lodash'
import ComponentCompiler from '@/components/ComponentCompiler'
export default {
  components: {
    ComponentCompiler,
    FormNav,
    FormWrapper
  },
  beforeRouteLeave(to, from, next) {
    if (!this.successRedirect && !isEqual(this.formCopy, this.form)) {
      this.$confirm('Czy na pewno chcesz opuścić stronę bez zapisania?')
        .then(() => {
          next()
        })
        .catch(() => {
          next(false)
        })
    } else {
      next()
    }
  },
  props: {
    id: {
      type: [Number, String],
      required: false
    },
    slug: {
      type: String,
      required: false
    },
    inModal: {
      // used as form in dialog
      type: Boolean,
      default: false
    },
    hideMultilingual: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      successRedirect: false,
      form: {},
      formCopy: {},
      errors: {},
      singleton: false,
      redirectRoute: null, // To extend
      lookupKwarg: 'id'
    }
  },
  computed: {
    service() {
      return null // To extend
    },
    lookupParams() {
      if (get(this, this.lookupKwarg)) {
        const params = {}
        params[this.lookupKwarg] = this.id
        return params
      } else if (this.slug) {
        return { slug: this.slug }
      }
      return null
    }
  },
  beforeDestroy() {
    window.onbeforeunload = () => {}
  },
  mounted() {
    this.fetchData()
    this.prepareFormCopyToPreventChangesLost()
  },
  methods: {
    getNavButtons() {},
    getFields() {},
    prepareFormCopyToPreventChangesLost() {
      this.formCopy = cloneDeep(this.form)
      window.onbeforeunload = () => {
        if (isEqual(this.formCopy, this.form)) {
          return null
        } else {
          return true
        }
      }
    },
    fetchData() {
      if (this.lookupParams || this.singleton) {
        this.loading = true
        this.service
          .get(this.lookupParams)
          .then(response => {
            this.form = response.data
            this.instanceFetchCallback()
            this.formCopy = cloneDeep(this.form)
          })
          .finally(() => {
            this.loading = false
          })
      }
      this.fetchDataCallback()
    },
    fetchDataCallback() {},
    instanceFetchCallback() {
      this.prepareFormCopyToPreventChangesLost()
    },
    onSubmit() {
      this.loading = true
      this.errors = {}
      const id = this.lookupParams
      const payload = this.getSavePayload()
      const service =
        id || this.singleton
          ? this.service.update(this.lookupParams, payload)
          : this.service.save(payload)
      service
        .then(response => {
          this.successRedirect = true
          this.successHandler(response)
        })
        .catch(this.errorHandler)
    },
    getSavePayload() {
      const payload = { ...this.form }
      return this.getPreparedPayload(payload)
    },
    getPreparedPayload(payload) {
      return payload
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-tabs__header {
  margin-bottom: 30px;
}
</style>
