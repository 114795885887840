<template>
  <div>
    <div class="wrapper">
      <div ref="thumbnailContainer" class="thumbnail-container">
        <iframe
          v-if="livePreviewUrl"
          ref="iframe"
          :src="livePreviewUrl"
          class="iframe"
          style="height: 80vh; width: 100%"
          frameborder="0"
        />
      </div>
    </div>
  </div>
</template>
<script>
import WidgetsInput from '@/components/widgets/WidgetsInput'

export default {
  mixins: [WidgetsInput],
  props: {
    previewContent: Array
  },
  computed: {
    livePreviewUrl() {
      return process.env.VUE_APP_MAP_CREATOR_URL
    }
  },
  watch: {
    previewContent: {
      immediate: true,
      handler() {
        this.postContentToIframe()
      }
    }
  },
  created() {
    window.addEventListener('message', this.messageFromIframeHandler)
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('message', this.messageFromIframeHandler)
    })
  },
  methods: {
    postContentToIframe() {
      const iframe = this.$refs.iframe
      if (!iframe || !this.previewContent) {
        return
      }
      const payload = { data: this.previewContent }
      iframe.contentWindow.postMessage(payload, '*')
    },
    messageFromIframeHandler(event) {
      const iframe = this.$refs.iframe
      const messageFromPreviewIframe =
      iframe && event.source === iframe.contentWindow
      
      if (!messageFromPreviewIframe) {
        return
      }

      const data = event.data
      if (data.type === 'mapData') {
        this.$emit(data.type, data.data)
      }

      if (data.type === 'ping') {
        this.postContentToIframe()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  text-align: center;
}
</style>
