<script>
import { PlaceCategory } from '@/api'
import fields from '@/components/fields'
import FormMixin from '@/crudBases/form/FormMixin.vue'

export default {
  mixins: [FormMixin],
  data() {
    return {
      redirectRoute: { name: 'Categories List' }
    }
  },
  computed: {
    service() {
      return PlaceCategory
    }
  },
  methods: {
    getFields() {
      return [
        new fields.Input({
          label: 'Nazwa',
          required: true,
          modelPath: [this, 'form', 'name'],
          multilingual: true
        }),
        new fields.Color({
          label: 'Kolor',
          required: true,
          modelPath: [this, 'form', 'color'],
          multilingual: false
        })
      ]
    }
  }
}
</script>
