<script>
import { PlaceCategory } from '@/api'
import ListMixin from '@/crudBases/list/ListMixin.vue'

export default {
  mixins: [ListMixin],
  data() {
    return {
      editRouteName: 'Edit Category',
      addRoute: { name: 'Add Category' },
      verboseName: 'Kategoria',
      verboseNamePlural: 'Kategorie',
      listFields: [
        { prop: 'id', label: 'id', width: 80 },
        { prop: 'name_pl', label: 'Nazwa (pl)' },
        { prop: 'name_en', label: 'Nazwa (en)' }
      ]
    }
  },
  computed: {
    service() {
      return PlaceCategory
    }
  }
}
</script>
