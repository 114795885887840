<script>
import { Map } from '@/api'
import fields from '@/components/fields'
import MapMixin from '@/crudBases/form/MapMixin.vue'

export default {
  mixins: [MapMixin],
  data() {
    return {
      redirectRoute: { name: 'Map List' },
      hideMultilingual: true
    }
  },
  computed: {
    service() {
      return Map
    }
  },
  methods: {
    getFields() {
      return [
        new fields.Input({
          label: 'Nazwa',
          required: true,
          modelPath: [this, 'form', 'name']
        }),
        new fields.Switch({
          label: 'Aktywna mapa',
          modelPath: [this, 'form', 'is_active']
        })
      ]
    }
  }
}
</script>
