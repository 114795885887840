import { render, staticRenderFns } from "./Formset.vue?vue&type=template&id=2db3fac8&scoped=true"
import script from "./Formset.vue?vue&type=script&lang=js"
export * from "./Formset.vue?vue&type=script&lang=js"
import style0 from "./Formset.vue?vue&type=style&index=0&id=2db3fac8&prod&lang=scss&scoped=true"
import style1 from "./Formset.vue?vue&type=style&index=1&id=2db3fac8&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2db3fac8",
  null
  
)

export default component.exports