import VueRouter from 'vue-router'

import Login from '@/views/Login.vue'
import NotFound from '@/views/404.vue'
import Home from '@/views/Home.vue'

const getView = view => require(`@/views/${view}`).default

let routes = [
  {
    path: '/login',
    component: Login,
    name: 'Login',
    hidden: true,
    meta: {
      guests: true
    }
  },
  {
    path: '/404',
    component: NotFound,
    name: '404',
    hidden: true,
    meta: {
      guests: true
    }
  },
  {
    path: '/',
    component: Home,
    name: 'Home',
    iconCls: 'el-icon-star-on',
    meta: { label: 'Home' },
    redirect: {
      name: 'Dashboard'
    },
    hidden: true
  },
  {
    path: '/dashboard',
    component: Home,
    iconCls: 'el-icon-s-home',
    meta: { label: 'Dashboard' },
    children: [
      {
        path: '',
        component: getView('Dashboard/Dashboard'),
        name: 'Dashboard',
        iconCls: 'el-icon-star-on',
        meta: { label: 'Home', permission: 'permissions.view_dashboard' }
      }
    ]
  },
  {
    path: '/pages',
    component: Home,
    iconCls: 'fa fa-desktop',
    meta: { label: 'Podstrony' },
    children: [
      {
        path: '',
        component: getView('Pages/PagesList'),
        name: 'Pages List',
        meta: { label: 'Lista stron', permission: 'pages.view_flatpage' }
      },
      {
        path: 'add',
        component: getView('Pages/PageForm'),
        name: 'Add Page',
        meta: { label: 'Dodaj stronę', permission: 'pages.add_flatpage' }
      },
      {
        path: ':id',
        props: true,
        component: getView('Pages/PageForm'),
        name: 'Edit Page',
        hidden: true,
        meta: { label: 'Edytuj stronę', permission: 'pages.change_flatpage' }
      }
    ]
  },
  {
    path: '/splash-screen',
    component: Home,
    iconCls: 'fa fa-bars',
    meta: { label: 'Splash Screen' },
    hidden: parseInt(process.env.VUE_APP_SPLASH_SCREEN_HIDDEN),
    children: [
      {
        path: 'splash-screen',
        component: getView('SplashScreen/SplashScreenForm'),
        name: 'Edit SplashScreen',
        meta: {
          label: 'Edytuj splash screen',
          permission: 'splash_screen.change_splashscreen'
        }
      }
    ]
  },
  {
    path: '/events',
    component: Home,
    iconCls: 'fa fa-calendar',
    meta: { label: 'Wydarzenia' },
    children: [
      {
        path: 'list',
        component: getView('Events/EventList'),
        name: 'Event List',
        meta: { label: 'Lista wydarzeń', permission: 'events.view_event' }
      },
      {
        path: 'add',
        component: getView('Events/EventForm'),
        name: 'Add Event',
        meta: { label: 'Dodaj wydarzenie', permission: 'events.add_event' }
      },
      {
        path: ':id',
        props: true,
        component: getView('Events/EventForm'),
        name: 'Edit Event',
        hidden: true,
        meta: { label: 'Edytuj wydarzenie', permission: 'events.change_event' }
      },
      {
        path: 'categories/list',
        component: getView('Events/EventCategoryList'),
        name: 'EventCategory List',
        meta: {
          label: 'Lista kategorii',
          permission: 'events.view_eventcategory'
        }
      },
      {
        path: 'categories/add',
        component: getView('Events/EventCategoryForm'),
        name: 'Add EventCategory',
        meta: {
          label: 'Dodaj kategorię',
          permission: 'events.add_eventcategory'
        }
      },
      {
        path: 'categories/:id',
        props: true,
        component: getView('Events/EventCategoryForm'),
        name: 'Edit EventCategory',
        hidden: true,
        meta: {
          label: 'Edytuj kategorię',
          permission: 'events.change_eventcategory'
        }
      }
    ]
  },
  {
    path: '/places',
    component: Home,
    iconCls: 'fa fa-map-marker',
    meta: { label: 'Miejsca' },
    children: [
      {
        path: 'maps',
        component: getView('Places/PlaceMapList'),
        name: 'Map List',
        meta: { label: 'Lista map', permission: 'places.view_place' }
      },
      {
        path: 'maps/add',
        component: getView('Places/PlaceMapForm'),
        name: 'Add Map',
        meta: { label: 'Dodaj mapę', permission: 'places.add_place' }
      },
      {
        path: 'maps/:id',
        props: true,
        component: getView('Places/PlaceMapForm'),
        name: 'Edit Map',
        hidden: true,
        meta: { label: 'Edytuj mapę', permission: 'places.change_place' }
      },
      {
        path: 'categories',
        component: getView('Places/PlaceCategoryList'),
        name: 'Categories List',
        meta: { label: 'Lista kategorii', permission: 'places.view_place' }
      },
      {
        path: 'categories/add',
        component: getView('Places/PlaceCategoryForm'),
        name: 'Add Category',
        meta: { label: 'Dodaj kategorie', permission: 'places.add_place' }
      },
      {
        path: 'categories/:id',
        props: true,
        component: getView('Places/PlaceCategoryForm'),
        name: 'Edit Category',
        hidden: true,
        meta: { label: 'Edytuj kategorie', permission: 'places.change_place' }
      },
      {
        path: '',
        component: getView('Places/PlaceList'),
        name: 'Place List',
        meta: { label: 'Lista miejsc', permission: 'places.view_place' }
      },
      {
        path: 'add',
        component: getView('Places/PlaceForm'),
        name: 'Add Place',
        meta: { label: 'Dodaj miejsce', permission: 'places.add_place' }
      },
      {
        path: ':id',
        props: true,
        component: getView('Places/PlaceForm'),
        name: 'Edit Place',
        hidden: true,
        meta: { label: 'Edytuj miejsce', permission: 'places.change_place' }
      }
    ]
  },
  {
    path: '/scheduled-notifications',
    component: Home,
    iconCls: 'fa fa-bell-o',
    meta: { label: 'Harmonogram powiadomień' },
    children: [
      {
        path: '',
        component: getView('ScheduledNotifications/ScheduledNotificationList'),
        name: 'ScheduledNotification List',
        meta: {
          label: 'Lista powiadomień',
          permission: 'notifications.view_schedulednotification'
        }
      },
      {
        path: 'add',
        component: getView('ScheduledNotifications/ScheduledNotificationForm'),
        name: 'Add ScheduledNotification',
        meta: {
          label: 'Zaplanuj Powiadomienie',
          permission: 'notifications.add_schedulednotification'
        }
      },
      {
        path: 'statistics',
        component: getView('ScheduledNotifications/Statistics'),
        name: 'Statistics',
        meta: {
          label: 'Statystyki powiadomień',
          permission: 'notifications.view_notification'
        }
      },
      {
        path: ':id',
        props: true,
        component: getView('ScheduledNotifications/ScheduledNotificationForm'),
        name: 'Edit ScheduledNotification',
        hidden: true,
        meta: {
          label: 'Edytuj powiadomienie',
          permission: 'notifications.change_schedulednotification'
        }
      }
    ]
  },
  {
    path: '/images',
    component: Home,
    iconCls: 'fa fa-image',
    meta: { label: 'Zdjęcia' },
    children: [
      {
        path: '',
        component: getView('Image/ImageList'),
        name: 'Image List',
        meta: { label: 'Lista zdjęć', permission: 'images.view_image' }
      },
      {
        path: 'add',
        component: getView('Image/ImageForm'),
        name: 'Add Image',
        meta: { label: 'Dodaj zdjęcie', permission: 'images.add_image' }
      },
      {
        path: 'catalogs',
        component: getView('Image/CatalogList'),
        name: 'Catalog List',
        meta: { label: 'Lista katalogów', permission: 'images.view_catalog' }
      },
      {
        path: 'catalogs/add',
        component: getView('Image/CatalogForm'),
        name: 'Add Catalog',
        meta: { label: 'Dodaj katalog', permission: 'images.add_catalog' }
      },
      {
        path: 'catalogs/:id',
        props: true,
        component: getView('Image/CatalogForm'),
        name: 'Edit Catalog',
        hidden: true,
        meta: { label: 'Edytuj katalog', permission: 'images.change_catalog' }
      },
      {
        path: ':id',
        props: true,
        component: getView('Image/ImageForm'),
        name: 'Edit Image',
        hidden: true,
        meta: { label: 'Edytuj zdjęcie', permission: 'images.change_image' }
      }
    ]
  },
  {
    path: '/attachments',
    component: Home,
    iconCls: 'fa fa-download',
    meta: { label: 'Załączniki' },
    children: [
      {
        path: '',
        component: getView('Attachments/AttachmentsList'),
        name: 'Attachments List',
        meta: {
          label: 'Lista załączników',
          permission: 'attachments.view_attachment'
        }
      }
    ]
  },
  {
    path: '/menu',
    component: Home,
    iconCls: 'fa fa-bars',
    meta: { label: 'Menu' },
    children: [
      {
        path: 'main-menu',
        component: getView('Menus/MainMenuForm'),
        name: 'Edit MainMenu',
        meta: {
          label: 'Edytuj menu główne',
          permission: 'menu.change_mainmenu'
        }
      }
    ]
  },
  {
    path: '/users',
    component: Home,
    iconCls: 'fa fa-group',
    meta: { label: 'Użytkownicy' },
    children: [
      {
        path: '',
        component: getView('User/UserList/'),
        name: 'User List',
        meta: {
          label: 'Lista użytkowników',
          permission: 'permissions.view_users_list'
        }
      },
      {
        path: 'add',
        component: getView('User/UserForm/'),
        name: 'Add User',
        meta: { label: 'Dodaj użytkownika', permission: 'users.add_user' }
      },
      {
        path: ':id',
        props: true,
        component: getView('User/UserForm/'),
        name: 'Edit User',
        hidden: true,
        meta: { label: 'Edytuj użytkownika', permission: 'users.change_user' }
      }
    ]
  },
  {
    path: '/settings',
    component: Home,
    iconCls: 'fa fa-cog',
    meta: { label: 'Ustawienia' },
    children: [
      {
        path: 'site',
        component: getView('Settings/SettingsForm'),
        name: 'Settings Form',
        meta: {
          label: 'Konfiguracja strony',
          permission: 'core.change_sitesettings'
        }
      }
    ]
  },
  {
    path: '*',
    hidden: true,
    redirect: { path: '/404' }
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const isUserLoggedIn = window.localStorage.getItem('token')
  if (!to.meta.guests && !isUserLoggedIn) {
    next({ name: 'Login', query: { next: to.path } })
    return
  }
  document.title = to.meta.label
    ? `${to.meta.label} - Panel administracyjny`
    : 'Panel administracyjny'
  next()
})

export default router
