<script>
import { Place, PlaceCategory } from '@/api'
import fields from '@/components/fields'
import FormMixin from '@/crudBases/form/FormMixin.vue'

export default {
  mixins: [FormMixin],
  data() {
    return {
      redirectRoute: { name: 'Place List' }
    }
  },
  computed: {
    service() {
      return Place
    }
  },
  methods: {
    getFields() {
      return [
        new fields.Input({
          label: 'Nazwa',
          required: true,
          modelPath: [this, 'form', 'name'],
          multilingual: true
        }),
        new fields.Input({
          label: 'Opis',
          required: false,
          modelPath: [this, 'form', 'description'],
          multilingual: true
        }),
        new fields.ForeignKey({
          label: 'Kategoria',
          modelPath: [this, 'form', 'category'],
          labelField: 'name_pl',
          valueField: 'id',
          clearable: true,
          service: PlaceCategory
        }),
        new fields.Image({
          label: 'Zdjęcie',
          modelPath: [this, 'form', 'icon'],
          required: false,
          multilingual: false
        })
      ]
    }
  }
}
</script>
